import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "~/components/layout"
import SEO from "~/components/seo"
// import IconQuestion from "~/images/icon_question.png"
import useDarkMode from "use-dark-mode"

const Heading = styled.div`
  font-size: 18px;
  margin-top: 66px;
`

const ContentWrap = styled.div`
  margin-top: 118px;

  @media screen and (max-width: 1024px) {
    margin-top: 30px;
  }
`

const DownloadCard = styled.div`
  padding: 8px 26px;

  .text {
    img {
      width: 24px;
      height: 24px;
    }
  }

  .qr-code {
    width: 142px;
    height: 142px;
    margin: 12px 0;
  }

  a {
    /* background: #13c750; */
    background: ${props => props.background};
    padding: 8px 26px;
  }
`

const IconQuestion = styled.div`
  width: 24px;
  height: 24px;
  background: ${props => props.background};
  mask: url(${props => props.icon}) no-repeat center / contain;
  -webkit-mask: url(${props => props.icon}) no-repeat center / contain;
  /* margin-right: 3px; */
`

const DownloadPage = ({
  data: {
    allMarkdownRemark: { edges },
    markdownRemark,
    theme,
    iconWeb
  },
  pageContext
}) => {
  const { frontmatter } = edges
    .find(
      item => item.node.file
        .split(".")[1]
        .replace("/", "") === pageContext.langKey
    )
    .node

  const { value } = useDarkMode()
  const isDarkMode = value;
  const themeColor = theme.edges[0].node.frontmatter;
  const iconWebs = iconWeb.edges[0].node.frontmatter;

  return (
    <Layout pageContext={pageContext} theme={themeColor}>
      <SEO
        title={markdownRemark.frontmatter.metadata.metaTitle}
        description={markdownRemark.frontmatter.metadata.metaDescription} />
      <Heading className="font-bold text-center">
        {markdownRemark.frontmatter.title}
      </Heading>
      <ContentWrap className="flex items-center justify-around md:flex-col">
        <div>
          <img src={`/${frontmatter.download_banner}`} alt="" />
        </div>
        <div className="flex">
          <DownloadCard className="flex flex-col justify-center"
            background={themeColor.light_theme.background_color.third}>
            <div className="flex justify-between font-bold text">
              <span>Android</span>
              <IconQuestion
                icon={`/${iconWebs.question}`}
                background={isDarkMode ? themeColor.dark_theme.background_color.fourth : themeColor.light_theme.background_color.fourth}
              />
            </div>
            <div className="qr-code">
              <img src={`/${frontmatter.download_androidqr}`} alt="" />
            </div>
            <a
              href={frontmatter.download_androidurl}
              className="font-bold text-center text-white rounded"
              target="_blank"
              rel="noreferrer"
            >
              {markdownRemark.frontmatter.download_button}
            </a>
          </DownloadCard>
          <DownloadCard className="flex flex-col justify-center"
            background={themeColor.light_theme.background_color.third}>
            <div className="flex justify-between font-bold text">
              <span>iOS</span>
              <IconQuestion
                icon={`/${iconWebs.question}`}
                background={isDarkMode ? themeColor.dark_theme.background_color.fourth : themeColor.light_theme.background_color.fourth}
              />
            </div>
            <div className="qr-code">
              <img src={`/${frontmatter.download_iosqr}`} alt="" />
            </div>
            <a
              href={frontmatter.download_iosurl}
              className="font-bold text-center text-white rounded"
              target="_blank"
              rel="noreferrer"
            >
              {markdownRemark.frontmatter.download_button}
            </a>
          </DownloadCard>
        </div>
      </ContentWrap>
    </Layout>
  )
}

export const query = graphql`
  query DownloadPageTemplate($langKey: String!) {
    allMarkdownRemark(filter: {collection: {eq: "common"}}) {
      edges {
        node {
          file
          frontmatter {
            download_androidqr
            download_androidurl
            download_banner
            download_iosqr
            download_iosurl
          }
        }
      }
    }

    markdownRemark(
      frontmatter: {templateKey: {eq: "download"}}
      fields: {langKey: {eq: $langKey}}
    ) {
      id
      frontmatter {
        metadata {
          metaDescription
          metaTitle
        }
        title
        download_button
      }
      fields {
        langKey
      }
    }

    theme: allMarkdownRemark(filter: {collection: {eq: "theme"}}) {
      edges {
        node {
          file
          frontmatter {
            light_theme {
              background_color {
                primary
                secondary
                third
                fourth
              }
              font_color {
                primary
                secondary
                third
              }
            }
            dark_theme {
              background_color {
                primary
                secondary
                third
                fourth
              }
              font_color {
                primary
                secondary
                third
              }
            }
          }
        }
      }
    }

    iconWeb: allMarkdownRemark(filter: {collection: {eq: "icons"}}) {
      edges {
        node {
          id
          frontmatter {
            question
            sort
            sun
            gift
            play
          }
        }
      }
    }
  }
`

export default DownloadPage
